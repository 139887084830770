import { useEffect } from 'react';
import { useQueryLoader } from 'react-relay';
//import Page, { PageQuery } from '../components/Page/Page.relay';
import Product, { ProductQuery } from '../components/Product/Product.relay';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';

const ProductRoute = ({ params }) => {
    const location = useLocation();
    const { slug } = useParams();
    const [preloadedQuery, loadProductQuery] = useQueryLoader(ProductQuery);

    useEffect(() => {
        // todo: this is a workaround. Route should never try to render until it matches
        if (location.pathname.startsWith('/products')) {
            loadProductQuery({ slug });
        }
    }, [loadProductQuery, slug, location]);

    return preloadedQuery ? <Product preloadedQuery={preloadedQuery} /> : null;
};

ProductRoute.propTypes = {
    params: PropTypes.shape({
        slug: PropTypes.string,
    }),
};

export default ProductRoute;
