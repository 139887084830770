import { normalizeUrl } from './utils/url';

export const permissions = {
    VERIFIED: 'VERIFIZIERT',
    SUPERVISOR: 'SUPERVISOR',
};

export const viewType = {
    COOKIE_NAME: 'viewtype',
    COOKIE_VALUE_MOBILE: 'm',
    COOKIE_VALUE_DESKTOP: 'd',
};

export const API_URL = normalizeUrl(process.env.REACT_APP_API_URL);
