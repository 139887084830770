import { useTranslation } from 'react-i18next';
import styles from './Product.module.scss';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TerminalItem from './Terminals/TerminalItem';

const Terminals = ({ terminals = [] }) => {
    const { t } = useTranslation();

    if (!terminals.length) return null;

    return (
        <div className={styles.Terminals}>
            <div className={styles.TerminalsItem}>
                <div className={styles.ProductSubtitle}>{t('products.detailedInfo3')}</div>
                <div className={styles.ProductSubtitle}>{t('products.detailedInfo4')}</div>
            </div>
            {terminals.map((terminal, index) => (
                <TerminalItem terminal={terminal} key={index} />
            ))}
        </div>
    );
};

const openingHourShape = PropTypes.shape({
    text: PropTypes.string,
    from: PropTypes.string,
    until: PropTypes.string,
});

Terminals.propTypes = {
    terminals: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            info: PropTypes.shape({
                address: PropTypes.string,
                zip: PropTypes.string,
                city: PropTypes.string,
                openingHours: PropTypes.arrayOf(openingHourShape),
            }).isRequired,
            agency: PropTypes.shape({
                titleEn: PropTypes.string,
                address: PropTypes.string,
                zip: PropTypes.string,
                city: PropTypes.string,
                phone: PropTypes.string,
                email: PropTypes.string,
                openingHours: PropTypes.arrayOf(openingHourShape),
            }).isRequired,
        })
    ).isRequired,
};

Terminals.displayName = 'Terminals';

export default memo(Terminals);
