import React, { memo, useMemo } from 'react';
import styles from '../Product.module.scss';
import { EmailIcon, PhoneIcon, SmartphoneIcon } from '../../Icon/Icon';
import Flag from '../../Flag/Flag';
import PropTypes from 'prop-types';

const formatPhoneNumber = (number) => number.replace(/[^0-9+]/g, '');
const ContactItem = ({ contact }) => {
    const avatarUrl = contact.avatarUrl ?? (contact.type === 'TEAM' ? '/team.svg' : '/avatar.svg');
    const formattedPhone = useMemo(
        () => contact.phoneNumber && formatPhoneNumber(contact.phoneNumber),
        [contact.phoneNumber]
    );
    const formattedMobile = useMemo(
        () => contact.mobileNumber && formatPhoneNumber(contact.mobileNumber),
        [contact.mobileNumber]
    );
    return (
        <div className={styles.ContactsItem}>
            <div className={styles.ContactsItemSummary}>
                <img
                    alt={contact.name}
                    className={styles.ContactsItemSummaryImage}
                    src={avatarUrl}
                />
                <div className={styles.ContactsItemSummaryText}>
                    <div className={styles.ContactsItemSummaryName}>{contact.name}</div>
                    {contact.position && (
                        <div className={styles.ContactsItemSummaryRole}>{contact.position}</div>
                    )}
                </div>
            </div>
            <div className={styles.ContactsItemSummaryDetail}>
                {contact.phoneNumber && (
                    <a href={`tel:${formattedPhone}`}>
                        <PhoneIcon className={styles.ContactsItemSummaryDetailIcon} />
                        {contact.phoneNumber}
                    </a>
                )}
                {contact.mobileNumber && (
                    <a href={`tel:${formattedMobile}`}>
                        <SmartphoneIcon className={styles.ContactsItemSummaryDetailIcon} />
                        {contact.mobileNumber}
                    </a>
                )}
                {contact.email && (
                    <div>
                        <EmailIcon className={styles.ContactsItemSummaryDetailIcon} />
                        <a href={`mailto:${contact.email}`}>{contact.email}</a>
                    </div>
                )}
                <div>
                    {contact.languages.map((language) => (
                        <Flag key={language} cc={language} />
                    ))}
                </div>
            </div>
        </div>
    );
};

ContactItem.propTypes = {
    contact: PropTypes.shape({
        name: PropTypes.string,
        position: PropTypes.string,
        phoneNumber: PropTypes.string,
        mobileNumber: PropTypes.string,
        email: PropTypes.string,
        languages: PropTypes.arrayOf(PropTypes.string),
        avatarUrl: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
};

ContactItem.displayName = 'ContactItem';

export default memo(ContactItem);
