import React, { memo } from 'react';
import styles from '../Product.module.scss';

const TerminalItem = ({ terminal }) => (
    <div className={styles.TerminalsItem}>
        <div className={styles.TerminalsItemTerminalInfo}>
            <div className={styles.TerminalsItemTitleUppercase}>{terminal.name}</div>
            <div className={styles.TerminalsItemContent}>
                <div>{terminal.info.address}</div>
                <div>
                    {terminal.info.zip} {terminal.info.city}
                </div>
                {terminal.info.openingHours.map((openingHour, index) => (
                    <div key={index}>
                        {openingHour.text}: {openingHour.from} - {openingHour.until}
                    </div>
                ))}
            </div>
        </div>
        <div className={styles.TerminalsItemAgencyInfo}>
            <div className={styles.TerminalsItemTitle}>{terminal.agency.title}</div>
            <div className={styles.TerminalsItemContent}>
                <div>{terminal.agency.address}</div>
                <div>
                    {terminal.agency.zip} {terminal.agency.city}
                </div>
                <a className={styles.TerminalsItemLink} href={`tel:${terminal.agency.phone}`}>
                    {terminal.agency.phone}
                </a>
                <a className={styles.TerminalsItemLink} href={`mailto:${terminal.agency.email}`}>
                    {terminal.agency.email}
                </a>
                {terminal.agency.openingHours.map((openingHour, index) => (
                    <div key={index}>
                        {openingHour.text}: {openingHour.from} - {openingHour.until}
                    </div>
                ))}
            </div>
        </div>
    </div>
);

TerminalItem.displayName = 'TerminalItem';

export default memo(TerminalItem);
