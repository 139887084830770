/**
 * @generated SignedSource<<2e5cdfe22768e1e005c8bb9b40387c93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "products",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "transport_sectors",
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductTransportSector",
    "kind": "LinkedField",
    "name": "productTransportSectors",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProductsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ebbb26a28e49c7e788dcce1bb6b8519d",
    "id": null,
    "metadata": {},
    "name": "ProductsQuery",
    "operationKind": "query",
    "text": "query ProductsQuery {\n  products {\n    id\n    active\n    title\n    transport_sectors\n    slug\n  }\n  productTransportSectors {\n    slug\n    title\n  }\n}\n"
  }
};
})();

node.hash = "6379eeb28d0828be91a2e0b201799395";

module.exports = node;
