import React, { memo } from 'react';
import styles from '../Product.module.scss';
import { API_URL } from '../../../constants';

const QuickFactItem = ({ fact, noteNumber }) => (
    <div className={styles.QuickFactsItem}>
        <div className={styles.QuickFactsIconContainer}>
            <img
                className={styles.QuickFactsIcon}
                src={`${API_URL}/images/content_icons/${fact.icon}.svg`}
                alt={fact.headline}
            />
        </div>
        <div className={styles.QuickFactsHeadlineContianer}>
            <h2 className={styles.QuickFactsHeadline}>
                {fact.headline}
                {fact.note && (
                    <sup className={styles.QuickFactsNoteSup} aria-label={`Note ${noteNumber}`}>
                        {noteNumber}
                    </sup>
                )}
            </h2>
        </div>
    </div>
);

QuickFactItem.displayName = 'QuickFactItem';

export default memo(QuickFactItem);
