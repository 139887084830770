import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { usePreloadedQuery, useQueryLoader } from 'react-relay';
import Component from '../Products/Products';

export const ProductsQuery = graphql`
    query ProductsQuery {
        products {
            id
            active
            title
            transport_sectors
            slug
        }
        productTransportSectors {
            slug
            title
        }
    }
`;

const Products = (props) => {
    const { products, productTransportSectors } = usePreloadedQuery(
        ProductsQuery,
        props.preloadedQuery
    );

    const initialProducts = useMemo(() => {
        const activeProducts = products.filter((product) => product.active);
        if (!props.sectorFilter?.length) return activeProducts;
        return activeProducts.filter((product) =>
            product.transport_sectors.some((sector) => props.sectorFilter.includes(sector))
        );
    }, [products, props.sectorFilter]);

    return (
        <Component
            products={initialProducts}
            productTransportSectors={productTransportSectors}
            sectorFilter={props.sectorFilter}
        />
    );
};

Products.propTypes = {
    preloadedQuery: PropTypes.object,
    sectorFilter: PropTypes.array,
};

export default Products;

export const ProductsBy = ({ sectorFilter }) => {
    const [preloadedQuery, loadProductsQuery] = useQueryLoader(ProductsQuery);
    useEffect(() => {
        loadProductsQuery({}, {});
    }, [ProductsQuery, loadProductsQuery]);

    return preloadedQuery ? (
        <Products preloadedQuery={preloadedQuery} sectorFilter={sectorFilter} />
    ) : null;
};
