import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
    DownloadsIcon,
    ExternalLinkIcon,
    HeadphoneIcon,
    HelpIcon,
    RequestQuotationIcon,
    SupportStickyIcon,
    UserIcon,
    WikiIcon,
} from '../Icon/Icon';
import useContacts from '../Contact/useContacts';
import ContactsList from '../Contact/ContactsList';
import SidePanel from '../SidePanel/SidePanel';
import FaqsList from '../Faq/FaqsList';
import useFaqs from '../Faq/useFaqs';
import ServiceHotline from '../ServiceHotline/ServiceHotline';
import DownloadsList from '../DownloadsList/DownloadsList';
import useDownloads from '../Downloads/useDownloads';
import TagsContext from '../../contexts/TagsContext';
import { useAppConfigContext } from '../../contexts/AppConfigContext';
import AppStateContext from '../../contexts/AppStateContext';
import ModalsContext from '../../contexts/ModalsContext';
import SpinnerSrc from '../AppLoadingIndicator/spinner.svg';
import EnquiryWizard from '../EnquiryWizard/EnquiryWizard';

import SupportBarButtonErrorBoundary from './SupportBarButtonErrorBoundary';

import styles from './SupportBar.module.scss';

const SupportButton = ({ icon, text, onClick }) => (
    <div className={styles.SupportBarItem} onClick={onClick}>
        {icon}
        <div className={styles.SupportBarTooltip}>{text}</div>
    </div>
);

const useServiceHotlineButton = (feature) => {
    const isOn = feature && feature.getOption('hotline_show') === 'always';
    return useContext(AppStateContext).showHotline && isOn;
};

const ServiceHotlineButton = ({ feature, onClick }) => {
    const { t } = useTranslation();
    const title = t('serviceHotline.title');

    if (!useServiceHotlineButton(feature)) return null;

    return (
        <SupportButton
            text={title}
            icon={<HeadphoneIcon className={styles.SupportBarIcon} />}
            onClick={() => onClick(title, <ServiceHotline />)}
        />
    );
};

const getFeatureTagsFromFeature = (feature) =>
    (feature?.getOption('feature_tags') || '').split('-----').filter((tag) => tag !== '') || [];

const useContactsButton = (feature) => {
    const featureTags = getFeatureTagsFromFeature(feature);
    const contextTags = useContext(TagsContext).tags;
    const contacts = useContacts(contextTags.length ? contextTags : featureTags);
    const isOn = feature && feature.getOption('contacts_show') === 'always';
    return !!contacts.length && isOn;
};

const ContactsButton = ({ isMobileView, feature, tags, onClick }) => {
    const { t } = useTranslation();
    const featureTags = getFeatureTagsFromFeature(feature);
    const contacts = useContacts(tags.length ? tags : featureTags);
    const title = t('contacts.title');

    if (!useContactsButton(feature)) return null;

    return (
        <SupportButton
            text={title}
            icon={<UserIcon className={styles.SupportBarIcon} />}
            onClick={() =>
                onClick(title, <ContactsList isMobileView={isMobileView} contacts={contacts} />)
            }
        />
    );
};

const useEquiryButton = (feature) => {
    const isOn = feature && feature.getOption('enquiry_show') === 'always';
    return isOn;
};

const EnquiryButton = ({ feature }) => {
    const { t } = useTranslation();
    const title = t('enquiry.title');
    const { showModal } = useContext(ModalsContext);

    if (!useEquiryButton(feature)) return null;

    return (
        <SupportButton
            text={title}
            icon={<RequestQuotationIcon className={styles.SupportBarIcon} />}
            onClick={() => showModal(title, <EnquiryWizard />)}
        />
    );
};

const useDownloadsButton = (feature) => {
    const featureTags = getFeatureTagsFromFeature(feature);
    const contextTags = useContext(TagsContext).tags;
    const downloads = useDownloads(contextTags.length ? contextTags : featureTags);
    const isOn = feature && feature.getOption('downloads_show') === 'always';

    return !!downloads.length && isOn;
};

const DownloadsButton = ({ feature, tags, onClick }) => {
    const { t } = useTranslation();
    const title = t('downloads.sidePanelTitle');
    const featureTags = getFeatureTagsFromFeature(feature);
    const downloads = useDownloads(tags.length ? tags : featureTags);

    if (!useDownloadsButton(feature)) return null;

    return (
        <SupportButton
            text={title}
            icon={<DownloadsIcon className={styles.SupportBarIcon} />}
            onClick={() => onClick(title, <DownloadsList downloads={downloads} />)}
        />
    );
};

const useRelatedTopicsButton = (feature) => {
    const relatedTopics = [];
    const isOn = feature && feature.getOption('related_show') === 'always';

    return !!relatedTopics.length && isOn;
};

const RelatedTopicsButton = ({ feature }) => {
    const { t } = useTranslation();
    //const relatedTopics = [];

    if (!useRelatedTopicsButton(feature)) return null;

    return (
        <SupportButton
            text={t('relatedTopics.title')}
            icon={<ExternalLinkIcon className={styles.SupportBarIcon} />}
        />
    );
};

const useFaqsButton = (feature) => {
    const featureTags = getFeatureTagsFromFeature(feature);
    const contextTags = useContext(TagsContext).tags;
    const faqs = useFaqs(contextTags.length ? contextTags : featureTags);
    const isOn = feature && feature.getOption('faqs_show') === 'always';

    return !!faqs.length && isOn;
};

const FaqsButton = ({ feature, tags, onClick }) => {
    const { t } = useTranslation();
    const title = t('faq.title');
    const featureTags = getFeatureTagsFromFeature(feature);
    const faqs = useFaqs(tags.length ? tags : featureTags);

    if (!useFaqsButton(feature)) return null;

    return (
        <SupportButton
            text={title}
            icon={<HelpIcon className={styles.SupportBarIcon} />}
            onClick={() => onClick(title, <FaqsList faqs={faqs} />)}
        />
    );
};

const useWikiButton = (feature) => {
    const wikis = [];
    const isOn = feature && feature.getOption('wiki_show') === 'always';

    return !!wikis.length && isOn;
};

const WikiButton = ({ feature }) => {
    const { t } = useTranslation();
    //const wikis = [];

    if (!useWikiButton(feature)) return null;

    return (
        <SupportButton
            text={t('wiki.title')}
            icon={<WikiIcon className={styles.SupportBarIcon} />}
        />
    );
};

const SupportBar = (props) => {
    const { isMobileView } = useAppConfigContext();
    const { tags } = useContext(TagsContext);
    const [selectedPanel, setSelectedPanel] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(isMobileView);
    const location = useLocation();

    useEffect(() => {
        setSelectedPanel(null);
        if (isMobileView) setIsCollapsed(isMobileView);
    }, [location.pathname, isMobileView, props.feature]);

    const buttons = [
        {
            component: (
                <ServiceHotlineButton
                    key={0}
                    feature={props.feature}
                    onClick={(title, content) => {
                        setSelectedPanel({ title, content });
                        if (isMobileView) setIsCollapsed(true);
                    }}
                />
            ),
            check: useServiceHotlineButton.bind(null, props.feature),
        },
        {
            component: (
                <SupportBarButtonErrorBoundary key={1}>
                    <ContactsButton
                        isMobileView={isMobileView}
                        feature={props.feature}
                        tags={tags}
                        onClick={(title, content) => {
                            setSelectedPanel({ title, content });
                            if (isMobileView) setIsCollapsed(true);
                        }}
                    />
                </SupportBarButtonErrorBoundary>
            ),
            check: useContactsButton.bind(null, props.feature),
        },
        {
            component: <EnquiryButton key={2} feature={props.feature} />,
            check: useEquiryButton.bind(null, props.feature),
        },
        {
            component: (
                <SupportBarButtonErrorBoundary key={3}>
                    <DownloadsButton
                        feature={props.feature}
                        tags={tags}
                        onClick={(title, content) => setSelectedPanel({ title, content })}
                    />
                </SupportBarButtonErrorBoundary>
            ),
            check: useDownloadsButton.bind(null, props.feature),
        },
        {
            component: (
                <SupportBarButtonErrorBoundary key={4}>
                    <RelatedTopicsButton feature={props.feature} />
                </SupportBarButtonErrorBoundary>
            ),
            check: useRelatedTopicsButton.bind(null, props.feature),
        },
        {
            component: (
                <SupportBarButtonErrorBoundary key={5}>
                    <FaqsButton
                        feature={props.feature}
                        tags={tags}
                        onClick={(title, content) => setSelectedPanel({ title, content })}
                    />
                </SupportBarButtonErrorBoundary>
            ),
            check: useFaqsButton.bind(null, props.feature),
        },
        {
            component: (
                <SupportBarButtonErrorBoundary key={6}>
                    <WikiButton feature={props.feature} />
                </SupportBarButtonErrorBoundary>
            ),
            check: useWikiButton.bind(null, props.feature),
        },
    ];

    return (
        <div
            className={classNames({
                [styles.SupportBarContainer]: true,
                [styles.SupportBarContainerCollapsed]: isCollapsed,
                [styles.SupportBarContainerMobile]: isMobileView,
                [styles.SupportBarContainerEmpty]: _getIsEmpty(buttons),
            })}
            data-testid='SupportBar'
        >
            {!!isMobileView && (
                <div
                    className={styles.SupportBarTrigger}
                    onClick={() => {
                        setIsCollapsed(!isCollapsed);
                    }}
                >
                    <SupportStickyIcon className={styles.SupportBarTriggerIcon} />
                </div>
            )}
            <div
                className={classNames({
                    [styles.SupportBar]: true,
                    [styles.SupportBarMobile]: isMobileView,
                })}
            >
                {buttons.map((button) => {
                    const Component = button.component;
                    return Component;
                })}
            </div>
            {selectedPanel && (
                <SidePanel
                    isMobileView={isMobileView}
                    title={selectedPanel.title}
                    onClose={() => setSelectedPanel(null)}
                >
                    {selectedPanel.content}
                </SidePanel>
            )}
        </div>
    );
};

export const SupportBarLoadingIndicator = () => {
    const { isMobileView } = useAppConfigContext();

    if (isMobileView) return null;

    return (
        <div
            className={classNames({
                [styles.SupportBar]: true,
                [styles.SupportBarMobile]: isMobileView,
            })}
        >
            <div className={styles.SupportBar}>
                <img src={SpinnerSrc} alt='' />
            </div>
        </div>
    );
};

SupportBar.propTypes = {};

export default SupportBar;

function _getIsEmpty(buttons) {
    var isEmpty = true;

    for (var i = 0; i < buttons.length; i++) {
        if (buttons[i].check()) {
            isEmpty = false;
            // we can't exit the loop because otherwise React fails
        }
    }

    return isEmpty;
}
