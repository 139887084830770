import Table from '../Table/Table';
import { useTranslation } from 'react-i18next';
import React, { useState, useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import emptyStateImage from '../DownloadsByCategory/empty-state.png';
import styles from './Products.module.scss';
import Input from '../Form/Input/Input';
import Tag from '../Tag/Tag';
import { EyeIcon } from '../Icon/Icon';
import FilterButtons from '../Downloads/FilterButtons';
import { useNavigate } from 'react-router-dom';

const MemoizedFilterButtons = memo(FilterButtons);

const Products = ({ products, productTransportSectors }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [filters, setFilters] = useState({
        search: '',
        categories: [],
    });

    const sectorTitleMap = useMemo(() => {
        return new Map(productTransportSectors.map((s) => [s.slug, s.title]));
    }, [productTransportSectors]);

    const availableCategories = useMemo(() => {
        const uniqueSectors = new Set(products.flatMap((p) => p.transport_sectors));
        return Array.from(uniqueSectors)
            .map((sector) => {
                const title = sectorTitleMap.get(sector);
                return title ? { id: sector, text: title } : null;
            })
            .filter(Boolean)
            .sort((a, b) => a.text.localeCompare(b.text));
    }, [products, sectorTitleMap]);

    const filteredProducts = useMemo(() => {
        const searchLower = filters.search.toLowerCase();
        return products.filter((product) => {
            const matchesSearch =
                !filters.search || product.title.toLowerCase().includes(searchLower);
            const matchesCategory =
                filters.categories.length === 0 ||
                product.transport_sectors.some((sector) => filters.categories.includes(sector));
            return matchesSearch && matchesCategory;
        });
    }, [products, filters]);

    const getSortedSectorTags = useCallback(
        (sectors) => {
            return sectors
                .map((sector) => {
                    const title = sectorTitleMap.get(sector);
                    return title ? { sector, title } : null;
                })
                .filter(Boolean)
                .sort((a, b) => a.title.localeCompare(b.title))
                .map(({ sector, title }) => (
                    <Tag key={sector} text={title} className={styles.CategoryTag} />
                ));
        },
        [sectorTitleMap]
    );

    const handleRowAction = useCallback(
        (row) => {
            navigate('/products/' + row.original.slug);
        },
        [navigate]
    );

    const handleSearch = useCallback((value) => {
        setFilters((prev) => ({ ...prev, search: value }));
    }, []);

    const handleCategoryChange = useCallback((categories) => {
        setFilters((prev) => ({ ...prev, categories }));
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: t('products.columnTitle'),
                accessor: 'title',
                sortable: true,
                Cell: ({ row }) => row.original.title,
            },
            {
                Header: t('products.columnSectors'),
                accessor: 'transport_sectors',
                Cell: ({ row }) => getSortedSectorTags(row.original.transport_sectors),
            },
            {
                Header: (
                    <Input
                        type='text'
                        value={filters.search}
                        onChange={handleSearch}
                        placeholder={t('products.search')}
                        icon='search'
                        className={styles.Search}
                    />
                ),
                accessor: 'id',
                Cell: () => (
                    <div className={styles.Last}>
                        <span className={styles.DownloadLink}>
                            <EyeIcon className={styles.EyeIcon} />
                        </span>
                    </div>
                ),
            },
        ],
        [t, filters.search, handleSearch, getSortedSectorTags]
    );

    return (
        <div className={styles.Products} data-testid='Products'>
            <MemoizedFilterButtons
                allOptionTranslation='products.allSectors'
                options={availableCategories}
                value={filters.categories}
                onChange={handleCategoryChange}
            />
            <div>
                <Table
                    columns={columns}
                    data={filteredProducts}
                    defaultSort={[{ id: 'title', desc: false }]}
                    rowAction={handleRowAction}
                    emptyState={
                        <div>
                            <img src={emptyStateImage} alt='' />
                            <div className={styles.EmptyHeadline}>
                                {t('products.emptyStateHeadline')}
                            </div>
                            <div className={styles.EmptySubHeadline}>
                                {t('products.emptyStateSubHeadline')}
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
};

Products.propTypes = {
    products: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            transport_sectors: PropTypes.arrayOf(PropTypes.string).isRequired,
            active: PropTypes.bool.isRequired,
        })
    ).isRequired,
    productTransportSectors: PropTypes.arrayOf(
        PropTypes.shape({
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default Products;
