import useContacts from '../Contact/useContacts';
import styles from './Product.module.scss';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ContactItem from './Contacts/ContactItem';

const Contacts = ({ tags = [] }) => {
    const contacts = useContacts(tags);
    const { t } = useTranslation();

    if (!contacts.length) return null;

    return (
        <div className={styles.Contacts}>
            <h1 className={styles.ProductSubtitle}>{t('products.contactsTitle')}</h1>
            <div className={styles.ContactsItemWrapper}>
                {contacts.map((contact, index) => (
                    <ContactItem key={index} contact={contact} />
                ))}
            </div>
        </div>
    );
};

Contacts.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string),
};

Contacts.displayName = 'Contacts';

export default memo(Contacts);
