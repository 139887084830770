import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import TagsContext from '../../contexts/TagsContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useVisibilityEffect } from '../../hooks/useVisibility';
import styles from './Product.module.scss';
import Image from '../Image/Image';
import QuickFacts, { getQuickFacts } from './QuickFacts';
import DetailedInfos, { getDetailedInfos } from './DetailedInfos';
import Terminals from './Terminals';
import TimeTable from './TimeTable';
import { API_URL } from '../../constants';
import Contacts from './Contacts';

const Product = ({ product }) => {
    const pageRef = useRef(null);
    const { setTags, clearTags } = useContext(TagsContext);

    useDocumentTitle(product.title, pageRef);
    const tags = product.tags?.edges?.map((tag) => tag.node.name) || [];

    useVisibilityEffect(
        (isVisible) => {
            if (isVisible) {
                setTags(tags);
            }
        },
        [tags, setTags, clearTags],
        pageRef
    );

    useVisibilityEffect(
        (isVisible) => {
            if (isVisible) {
                return clearTags;
            }
        },
        [clearTags],
        pageRef
    );

    return (
        <article ref={pageRef} className={styles.Product} data-testid='Product'>
            <h1 className={styles.ProductTitle}>{product.title}</h1>
            <div className={styles.ProductImageContainer}>
                <img src={`${API_URL}/asset/${product.asset_id}`} alt={product.title} />
            </div>
            <QuickFacts facts={getQuickFacts(product)} />
            <TimeTable timetable={product.timetable} />
            <Terminals terminals={product.terminals} />
            <DetailedInfos infos={getDetailedInfos(product)} />
            <Contacts tags={tags} />
        </article>
    );
};

const quickFactShape = PropTypes.shape({
    icon: PropTypes.string,
    headline: PropTypes.string,
    note: PropTypes.string,
});

Product.propTypes = {
    product: PropTypes.shape({
        title: PropTypes.string,
        asset_id: PropTypes.number,
        quickFact1: quickFactShape,
        quickFact2: quickFactShape,
        quickFact3: quickFactShape,
        quickFact4: quickFactShape,
        quickFact5: quickFactShape,
        quickFact6: quickFactShape,
        quickFact7: quickFactShape,
        quickFact8: quickFactShape,
        quickFact9: quickFactShape,
        timetable: PropTypes.string,
        terminals: PropTypes.array,
    }).isRequired,
    showHotline: PropTypes.string,
};

Product.displayName = 'Product';

export default Product;
