import styles from './Product.module.scss';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const TimeTable = ({ timetable = null }) => {
    const { t } = useTranslation();

    if (!timetable) return null;

    return (
        <div className={styles.TimeTable}>
            <h2 className={styles.ProductSubtitle}>{t('products.timetableTitle')}</h2>
            <div
                className={styles.TimeTableTable}
                dangerouslySetInnerHTML={{ __html: timetable }}
            />
            <p className={styles.TimeTableNotice}>{t('products.timetableNotice')}</p>
        </div>
    );
};

TimeTable.propTypes = {
    timetable: PropTypes.string.isRequired,
};

TimeTable.displayName = 'TimeTable';
export default memo(TimeTable);
