import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import React from 'react';
import { usePreloadedQuery } from 'react-relay';
import LocalErrorBoundary from '../ErrorBoundary/LocalErrorBoundary';
import Component from '../Product/Product';

export const ProductQuery = graphql`
    query ProductQuery($slug: String!) {
        product(slug: $slug) {
            title
            slug
            asset_id
            timetable
            tags(first: 20) {
                edges {
                    node {
                        name
                        slug
                    }
                }
            }
            quickFact1 {
                headline
                note
                icon
            }
            quickFact2 {
                headline
                note
                icon
            }
            quickFact3 {
                headline
                note
                icon
            }
            quickFact4 {
                headline
                note
                icon
            }
            quickFact5 {
                headline
                note
                icon
            }
            quickFact6 {
                headline
                note
                icon
            }
            quickFact7 {
                headline
                note
                icon
            }
            quickFact8 {
                headline
                note
                icon
            }
            quickFact9 {
                headline
                note
                icon
            }
            detailedInfo1 {
                content
            }
            detailedInfo2 {
                content
            }
            detailedInfo3 {
                content
            }
            detailedInfo4 {
                content
            }
            detailedInfo5 {
                content
            }
            detailedInfo6 {
                content
            }
            detailedInfo7 {
                content
            }
            detailedInfo8 {
                content
            }
            detailedInfo9 {
                content
            }
            terminals {
                active
                name
                info {
                    address
                    country
                    zip
                    city
                    phone
                    email
                    openingHours {
                        text
                        from
                        until
                    }
                }
                agency {
                    title
                    notes
                    openingHoursText
                    address
                    country
                    zip
                    city
                    phone
                    email
                    openingHours {
                        text
                        from
                        until
                    }
                }
                facilityInfo {
                    dangerousGoodsAllowed
                    parkingPossible
                    dangerousGoodsStorageAvailable
                    repairService
                    cleaning
                    reeferPlugsAvailable
                    customsOnSite
                }
            }
        }
    }
`;

const Product = (props) => {
    const { product } = usePreloadedQuery(ProductQuery, props.preloadedQuery);
    return (
        <LocalErrorBoundary>
            <Component product={product} />
        </LocalErrorBoundary>
    );
};

Product.propTypes = {
    preloadedQuery: PropTypes.object,
};

export default Product;
