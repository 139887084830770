import styles from './Product.module.scss';
import React, { memo } from 'react';
import Panel from '../Panel/Panel';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const getDetailedInfos = (product) => {
    if (!product) return [];

    return Array.from({ length: 9 }, (_, i) => i + 1).map((num) => ({
        headline: `products.detailedInfo${num}`,
        content: product[`detailedInfo${num}`]?.content || '',
    }));
};

const DetailedInfos = ({ infos = [] }) => {
    const { t } = useTranslation();

    const validInfos = infos.filter((info) => Boolean(info?.content));

    if (!validInfos.length) return null;

    return (
        <div>
            <h1 className={styles.ProductSubtitle}>{t('products.detailedInfoTitle')}</h1>
            {validInfos.map((info, index) => (
                <Panel title={t(info.headline)} key={index}>
                    <div
                        className={styles.DetailedInfosContent}
                        dangerouslySetInnerHTML={{
                            __html: info.content || '',
                        }}
                    />
                </Panel>
            ))}
        </div>
    );
};

DetailedInfos.propTypes = {
    infos: PropTypes.arrayOf(
        PropTypes.shape({
            headline: PropTypes.string,
            content: PropTypes.string,
        })
    ).isRequired,
};

DetailedInfos.displayName = 'DetailedInfos';

export default memo(DetailedInfos);
