import styles from './Product.module.scss';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import QuickFactItem from './QuickFacts/QuickFactItem';

export const getQuickFacts = (product) => {
    const factKeys = Array.from({ length: 9 }, (_, i) => `quickFact${i + 1}`);
    return factKeys.map((key) => product[key]).filter(Boolean);
};

const QuickFacts = ({ facts = [] }) => {
    const { t } = useTranslation();

    const notes = useMemo(
        () => facts.filter((fact) => fact.note).map((fact) => fact.note),
        [facts]
    );

    if (!facts.length) return null;

    return (
        <>
            <h1 className={styles.ProductSubtitle}>{t('products.quickFactsTitle')}</h1>
            <div className={styles.QuickFacts}>
                {facts.map((fact, index) => (
                    <QuickFactItem
                        key={fact.icon || index}
                        fact={fact}
                        noteNumber={notes.findIndex((note) => note === fact.note) + 1}
                    />
                ))}
            </div>
            <div>
                {notes.length > 0 && (
                    <ol className={styles.QuickFactsNotes}>
                        {notes.map((note, index) => (
                            <li key={index}>{note}</li>
                        ))}
                    </ol>
                )}
            </div>
        </>
    );
};

QuickFacts.propTypes = {
    facts: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string.isRequired,
            headline: PropTypes.string.isRequired,
            note: PropTypes.string,
        })
    ).isRequired,
};

QuickFacts.displayName = 'QuickFacts';

export default memo(QuickFacts);
